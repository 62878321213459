<template>
  <v-app>
    <v-card flat>
      <v-card-title>
        Starting a new {{ quiz.title || '' }}
      </v-card-title>
      <v-card-text class="subtitle-1">
          <p>
           Scheduled at : <strong>{{quiz.schedule_at | moment("dddd, MMMM Do YYYY") }}  {{quiz.start_time | moment('MMMM Do YYYY, h:mm:ss a')}}</strong>
          </p>
        <p>
          You may take as many Practice Exams as you like. We recommend you take at least two to become familiar with
          all of the exam tools you’ll need to use and the way the questions are expressed. You may also find out
          tutorial videos and FAQs helpful in learning how to use our software.


          Please note that your Practice Exam will only be completed once you press the ‘Submit Exam’ button. Incomplete
          exams can be resumed from this page, but cannot be reviewed on your ‘Results’ page.
        </p>

        <v-row v-if="can_start_exam">
          <v-col>
            <v-checkbox v-model="accept_term">
              <template v-slot:label>
                <div>
                  I agree to give an Exam

                </div>
              </template>
            </v-checkbox>
          </v-col>
          <v-col v-if="quiz.is_protected">
            <v-text-field :error="$v.pass_code.$error || error_msg?true:false"
                          :append-icon="show_password ? 'mdi-eye' : 'mdi-eye-off'"
                          v-model="pass_code"
                          :type="show_password ? 'text' : 'password'"
                          outlined
                          dense
                          label="Pass Code"
                          @click:append="show_password = !show_password"
            >

            </v-text-field>
            <span class="text-danger" v-if="$v.pass_code.$error">Passcode is required</span>
            <span class="text-danger" v-text="error_msg"></span>
          </v-col>
          <v-col>
            <v-btn
                @click="startQuiz"
                :disabled="!accept_term"
                color="success"
                depressed
                dense
            >
              Validate & Start Quiz

            </v-btn>
            <v-btn
                dark
                class="ml-2"
                color="red"
                depressed
                dense
            >
              Cancel

            </v-btn>
          </v-col>
        </v-row>
        <v-row v-else>
          <v-col>
            <p class="font-weight-bold text-danger subtitle-1">Sorry! This Quiz/exams has expired</p>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-app>
</template>

<script>
import {QuizMixin} from "@/mixins/QuizMixin";
import QuizService from "@/core/services/quiz/QuizService";
import {required} from "vuelidate/lib/validators";
import error from "@/view/pages/error/Error";

const quizService = new QuizService();
export default {
  mixins: [QuizMixin],
  validations: {
    pass_code: {required}
  },
  name: "Quiz-start",
  data() {
    return {
      can_start_exam:false,
      accept_term: false,
      show_password: false,
      pass_code: null,
      error_msg: null,
      date: this.$moment(1),
    }
  },
  mounted() {
    this.getQuiz()
    this.checkDueDate()
  },
  methods: {
    setupAndStartQuiz() {
      if(this.quizId) {
        this.isBusy = true
        let currentUserId = this.current_user.id
        let quizId = this.quiz.id
        quizService
            .setupAndStartQuiz(currentUserId, quizId).then(response => {
              if(response.data.status=='ERROR'){
                this.$snotify.error(response.data.message)
              }else{
                this.$store.dispatch('setQuizAttempt', response.data.attempt)
                this.$router.push({name: 'quiz-start', params: {quiz_id: quizId}})
              }
          })
            .catch(err => {
              this.$snotify.error("Please try again later")
              this.isBusy = false
        })
      }
    },
    validatePasscode(userId, passcode) {
      if(this.quizId){
        quizService.validatePasscode(userId, this.quiz.id, passcode).then(response => {
          if (!response.data.status) {
            this.error_msg = response.data.msg
          } else {
            this.setupAndStartQuiz(this.current_user.id, this.quiz.id)
          }
        }).catch(err => {
          this.isBusy = false
        })
      }

    },
    startQuiz() {
      if (this.quiz.is_protected){
      this.$v.pass_code.$touch()
      if (this.$v.pass_code.$error) {
        setTimeout(() => {
          this.$v.pass_code.$reset();
        }, 3000)
      } else {
        this.validatePasscode(this.current_user.id, this.pass_code)
      }
    }else{
        this.setupAndStartQuiz()
      }
    },
    checkDueDate(){
      quizService.checkQuizDateAndTime(this.quizId).then(response=>{
        this.can_start_exam =  response.data.status;
      }).catch(error=>{
        this.can_start_exam =  false;
      });

    },
  },
  computed:{
    quizId() {
      return this.$route.params.quiz_id;
    },
  }
}
</script>

<style scoped>

</style>